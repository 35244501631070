import "./assets/css/App.css";
import { Logo } from "./components/Logo";
import Home from "./components/Home.js";
import PrivacyPolicies from "./components/PrivacyPolicies";


const App = () => {

  const url = window.location.pathname;

  return (
    <>
      {url === "/privacypolicies" ? (
        <PrivacyPolicies/>
      ):(
      <div className="fullPage">
        <div className="top-bar">
          <Logo />
        </div>
        <div className='home-position'>
          <Home/>
        </div>
      </div>
      )}       
    </>
  )
}

export default App