import React from 'react'
import "../assets/css/privacyPolicies.css";

import { Logo } from "../components/Logo";

const PrivacyPolicies = () => {
  return (
    <div className="fullPage">
        <div className="top-bar">
            <Logo />
          </div>
        <div className='container-topbar'>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1 className='policies-title'>
            Privacidad
            </h1>
            <br/>
            <h2>
            Políticas de Privacidad y Protección de datos personales de Televentas Argentina S.A.
            </h2>
            <h4>La Política de Privacidad de televentasargentina.com es aplicable a todos los usuarios del sitio web desde el momento de la navegación por el mismo y/o de la aceptación de los Términos y Condiciones de Uso, y tiene como objetivo que los usuarios conozcan el trato que Televentas Argentina S.A. les dará a sus datos personales.</h4>
            <br/>
            <h3>1. Disposiciones Generales.</h3>
            <p>
            La información personal de los usuarios es de extrema importancia para Televentas Argentina S.A.. Es por ello que se utilizan todos los medios administrativos y técnicos a nuestro alcance así como todos los resguardos legales necesarios para asegurar la protección de los datos personales y la privacidad de acuerdo a lo establecido por la Ley 25.326 de Protección de Datos Personales, el Decreto Reglamentario 1558/2001 y las Disposiciones de la Dirección Nacional de Protección de Datos Personales (DNPDP).
            </p>
            <p>
            La presente Política de Privacidad se aplica a toda información personal de los usuarios que se hayan obtenido por la utilización del sitio web y de la contratación de los servicios ofrecidos en el mismo.
            </p>
            <br/>
            <h3>2. Formas de obtención de datos.</h3>
            <p>
            televentasargentina.com recogerá datos personales de los usuarios a través de formularios e interacciones dentro del sitio web, como los Newsletters, las comunicaciones electrónicas, y los perfiles en redes sociales, así como también a través de los contratos comerciales que realice.
            </p>
            <br/>
            <h3>3. Datos recolectados.</h3>
            <p>
            Los usuarios del sitio web proveen de dos tipos de informaciones a televentasargentina.com: (1) datos que el usuario provee y carga voluntariamente al Sitio cuando decide suscribirse a alguno de los Servicios, y (2) datos de tráfico que se genera al navegar por el sitio web. Dentro de la primera categoría, los datos personales que televentasargentina.com recolecta a través de las distintas secciones del sitio web son: Nombre y apellido, empresa o estudio, teléfono, email, profesión, especialidad y mensaje. 
            </p>
            <p>
              Respecto a la segunda categoría de datos, televentasargentina.com recibe información tal como: tipo de navegador del usuario, dirección IP, tiempo de duración de la navegación por el sitio web, página que ha reenviado al usuario al sitio web, tráfico y preferencias dentro del sitio, etc.
            </p>
            <br/>
            <h3>4. Confidencialidad.</h3>
            <p>
            La información personal es totalmente confidencial y televentasargentina.com tomará las medidas de seguridad para evitar su uso desautorizado de conformidad con lo dispuesto en la Ley 25.326 de Protección de Datos Personales de Argentina, y en concordancia con los estándares normativos de privacidad de la Unión Europea y Latinoamérica. Las comunicaciones privadas que pudieran darse entre el personal de televentasargentina.com y los usuarios también serán consideradas como confidenciales. El acceso a toda esta información estará restringido mediante herramientas tecnológicas y estrictos controles internos. Sin embargo no se considerarán confidenciales las comunicaciones electrónicas entre los usuarios o entre estos y televentasargentina.com con sugerencias y/o consultas y/o en cumplimiento de condiciones de promociones o concursos, newsletters, publicidad o información solicitada. En estos casos televentasargentina.com podrá retener y utilizar libremente dichas comunicaciones.
            </p>
            <br/>
            <h3>5. Finalidad.</h3>
            <p>
            En cumplimiento de lo establecido en los arts. 2, 7 y 8 de la Ley 25.326, televentasargentina.com no solicitará información que sea incompatible con la finalidad del sitio web o de sus servicios, ni que directa o indirectamente revele datos sensibles de los usuarios, como ser datos que revelan origen racial y étnico, opiniones políticas, convicciones religiosas, filosóficas o morales, afiliación sindical, información referente a la salud o a la vida sexual. De la misma manera televentasargentina.com sugiere a los usuarios no incluir esta información en el sitio web. Asimismo, los usuarios prestan su consentimiento a que televentasargentina.com pueda utilizar la dirección de correo electrónico para fines publicitarios, de marketing y para promociones.
            </p>
            <br/>
            <h3>6. Administración de la información.</h3>
            <p>
            televentasargentina.com utilizará la información para los siguientes fines: a) identificación y autenticación, b) administración y gestión comercial, c) mejora del servicio, d) fines estadísticos, e) envío de notificaciones, promociones o publicidad y actividades similares. Los datos personales no serán difundidos ni empleados para un fin distinto o incompatible al tenido en cuenta al ser ingresados en la base. televentasargentina.com podrá emplear a otras compañías y/o personas físicas para llevar a cabo tareas o funciones en su nombre. En caso de cesión o utilización para un fin diferente, televentasargentina.com solicitará a los usuarios su consentimiento libre, expreso e informado. En el caso de venta, cesión, fusión, consolidación, venta de la totalidad o parte sustancial de sus activos, televentasargentina.com podrá revelar o transmitir dicha información a terceros.
            </p>
            <br/>
            <h3>7. Registro de la Base de Datos.</h3>
            <p>
            Los datos recabados por el sitio web son incorporados a una base de datos de la cual televentasargentina.com es responsable. La misma se encuentra registrada en la Dirección Nacional de Protección de Datos Personales del Ministerio de Justicia y Derechos Humanos de la Nación (República Argentina) bajo el registro N° 71.340, en cumplimiento de lo dispuesto en el art. 3 de la Ley 25.326. La Dirección Nacional de Protección de Datos Personales, Órgano de Control de la Ley Nº 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales. El sitio web para consultas sobre responsables inscriptos es www.jus.gob.ar/datospersonales.
            </p>
            <br/>
            <h3>8. Derechos.</h3>
            <p>
            El usuario tiene la facultad de ejercer el derecho de acceso a sus datos en forma gratuita a intervalos no inferiores a seis (6) meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº 25.326. La Dirección Nacional de Protección de Datos Personales, Órgano de Control de la Ley Nº 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales. Asimismo, se deja constancia que el titular de los datos podrá en cualquier momento solicitar el retiro o bloqueo de su nombre de los bancos de datos (Ley 25.326, art. 27, inc. 3). Esto se hace en cumplimiento de lo establecido en el Decreto 1558/2001, art. 27, párrafo 3, que establece que “en toda comunicación con fines de publicidad que se realice por correo, teléfono, correo electrónico, Internet u otro medio a distancia a conocer, se deberá indicar, en forma expresa y destacada, la posibilidad del titular del dato de solicitar el retiro o bloqueo, total o parcial, de su nombre de la base de datos. A pedido del interesado, se deberá informar el nombre del responsable o usuario del banco de datos que proveyó la información”.
            </p>
            <br/>
            <h3>9. Procedimiento.</h3>
            <p>
            Para que el Usuario pueda hacer efectivo su derecho de acceso, rectificación, actualización, supresión y confidencialidad de datos, deberá tener en consideración lo siguiente: a. La solicitud de obtención de información, rectificación, actualización o supresión de datos personales debe efectuarse por carta documento dirigida a Av. San Martín 972, Mendoza, Dpto. Capital, Provincia de Mendoza, República Argentina, acreditando la identidad y el legítimo derecho. b. Una vez recibida y aprobada dicha solicitud, televentasargentina.com la contestará en lenguaje accesible y entendible, previa confirmación de identidad, dentro de los diez (10) días corridos para el caso de pedido de información mientras que, para el pedido de rectificación, actualización o supresión de datos, el responsable contará con cinco (5) días hábiles para contestar. c. televentasargentina.com informará de la forma más concreta y claramente posible y contestará de acuerdo a la preferencia que expresamente haya manifestado el interesado en su solicitud. En ningún caso el informe podrá revelar datos pertenecientes a terceros. d. La información podrá ser denegada cuando de tal modo se pudieran obstaculizar actuaciones judiciales o administrativas en curso.
            </p>
            <br/>
            <h3>10. Seguridad de la información.</h3>
            <p>
            televentasargentina.com se ocupa por mantener todo el tiempo la seguridad de la información personal que brindan los usuarios, implementando las medidas técnicas y organizativas internas necesarias para garantizar la seguridad y confidencialidad de los datos y tratando por todos los medios de evitar el acceso no autorizado a los mismos. Sin embargo, en razón del estado actual de la tecnología, https://televentasargentina.com no puede garantizar que el acceso no autorizado no ocurrirá nunca.
            </p>
            <br/>
            <h3>11. Uso de cookies.</h3>
            <p>
            Los usuarios conocen y aceptan de plena conformidad que televentasargentina.com podrá utilizar un sistema de seguimiento mediante el uso de cookies. Las Cookies son archivos que se alojan en el disco rígido, con una duración limitada en el tiempo que ayudan a personalizar los servicios. Las mismas permitirán recoger datos a efectos de la autenticación de usuarios, validación de identidad y para evaluar el comportamiento y la demografía de los usuarios con el fin de ofrecerles un mejor servicio. Asimismo, podrán utilizarse con fines estadísticos tales como: fecha de la primera visita, número de veces que se ha visitado, fecha de la última visita, URL y dominio de la que proviene, explorador utilizado, resolución de la pantalla, entre otras acciones del Usuario. También son utilizadas las Cookies para promover y hacer cumplir las reglas y seguridad del sitio. Toda esta información no será asociada a los datos personales de los usuarios. No obstante, el Usuario puede desactivar y/o eliminar estas cookies a través de su navegador de Internet, sabiendo que con esta opción, es posible que no se puedan acceder a ciertos contenidos del Sitio. televentasargentina.com pone en su conocimiento que en ocasiones, puede haber existencia de cookies o archivos de similares características instalados por terceros en algunos enlaces con el sitio web que no son controlados por televentasargentina.com. televentasargentina.com es responsable solamente por la utilización de Cookies por su propio sitio web y no por el uso de Cookies por parte de anunciantes.
            </p>
            <br/>
            <h3>12. Enlaces con otros sitios web.</h3>
            <p>
            El sitio web puede contener enlaces o links publicitarios con sitios web de terceros. Sin embargo televentasargentina.com no dispone de control alguno ni ostenta responsabilidad alguna sobre las políticas o medidas de protección de datos de otras aplicaciones y/o sitios web.
            </p>
            <p>
            La presente Política de Privacidad forma parte integrante de los Términos y Condiciones de Uso del Sitio.
            </p>
            <br/>
        </div>
    </div>
  )
}

export default PrivacyPolicies